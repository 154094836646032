import { CKEditor } from 'ckeditor4-react';

const HtmlEditor = (props) => {

  const cfg = {
    enterMode:2,//window.CKEDITOR.ENTER_BR,
    height:props.height || '80px',
    contentsCss:'/editor.css',
    autoGrow_minHeight:'60px',
    autoGrow_maxHeight:'300px',
    removePlugins: 'elementspath',
    resize_enabled: false,
    toolbar: [
      [ 'Source' ],
      [ 'Bold', 'Italic','Link'],
      [ 'Undo', 'Redo' ],
    ],
    readOnly: props.disabled

  }

  const onChange = (event, editor)=>{
    props.onChange({value:event.editor.getData()})
    console.log('onChange>>>>',)
  }

  
  return (

    <CKEditor
    {...props}
   config={cfg}
                initData={props.value}
                onChange={onChange}
                events={{
                  "change": onChange
                }}

                onInstanceReady={ () => {
                   
                } }
            />

  );
};

export default HtmlEditor;
